import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "../reveals/TextReveal"
import BlockWrapper from "../technical/BlockWrapper"

const IntroTextBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth="container"
      showHeadline="true"
      showButtons="true"
      blockDirection="vertical"
      className=""
    >
      <div className="mt-4 -mx-grid">
        <TextReveal className="lg:w-1/2 px-grid">
          <Richtext flow={block.flow} text={block.text} className="prose-lg" />
        </TextReveal>
      </div>
    </BlockWrapper>
  )
}

export default IntroTextBlock
